import React from "react"
import Layout from "../../components/layout"

import newsPostStyles from "../../styles/templates/news-post.module.css"

import setup1 from "../../images/news/article-3/pict of xowii 1.png"
import setup2 from "../../images/news/article-3/pict of xowii 2.png"

const Article3Page = () => {
    return (
        <Layout location="news">
        <div className={ newsPostStyles.article }>
            <h1>SAMPLE STANDARD SET-UPS</h1>
            <h3>November 26, 2020</h3>
            <div className={ newsPostStyles.imageRow }>
                <img src={setup1} alt=""/>
                <img src={setup2} alt=""/>
            </div>
            <p>Above pics are standard set-ups for conferences. Special events specialist Team ED provides standard set-ups for quick mounts of events when clients such as organizers and companies find themselves lacking in time. Otherwise, Team ED customizes for its clients event executions for the delivery of unique, impactful and delightful experiences.</p>
            <button onClick={() => {
                window.history.back()
            }}>Go Back</button>
        </div>
        </Layout>
    )
}

export default Article3Page